<template>
  <cp-general-modal
    ref="systemCountries"
    ok-title="Edit Countries"
    title="Edit Country"
    title-icon="ion ion-md-create"
    size="lg"
    :default-value="countryData"
    @onOk="handleSubmit"
  >
    <cp-input
      name="countryCode"
      label="Country Code"
      disabled
    />

    <cp-select
      name="type"
      label="Type"
      :options="typeOptions"
    />

    <cp-select
      v-model="agreement1Name"
      name="agreement1"
      label="Initial Agreement"
      :options="showAgreementsList"
    />

    <cp-textarea
      :value="getAgreementFieldByName('text', agreement1Name)"
      label="Agreement Text"
      :rows="5"
      disabled
    />

    <cp-select
      v-model="agreement2Name"
      name="agreement2"
      label="Accredit agreements"
      :options="showAgreementsList"
    />

    <cp-textarea
      :value="getAgreementFieldByName('text', agreement2Name)"
      label="Agreement Text"
      :rows="5"
      disabled
    />
    <cp-select
      v-model="model.accreditationText"
      name="accreditationText"
      :label="$t('defaultCountrySettings.label.accreditationText')"
      :options="accreditationTileOptions"
    />
    <cp-textarea
      v-if="showOtherText"
      v-model="accreditationTextBox"
      :label="$t('defaultCountrySettings.label.customTitle')"
      :rows="5"
    />
    <div class="row form-group justify-content-sm-between">
      <div
        v-for="toggle in toggles"
        :key="toggle"
        class="col-5 d-flex align-items-center justify-content-sm-between mt-3"
      >
        <span>
          {{ $t(`defaultCountrySettings.label.${toggle}`) }}
        </span>
        <cp-switch
          v-model="model[toggle]"
          :name="toggle"
        />
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import { mapGetters } from 'vuex';

import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpTextarea from '~/components/common/standalone-components/inputs/cp-textarea';

import { CpSwitch } from '~/components/common/standalone-components/inputs';

export default {
  name: 'SystemCountriesModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
    CpTextarea,
    CpSwitch,
  },
  props: {
    countryData: {
      type: Object,
      default: () => {},
    },
    showAgreementsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      model: {},
      agreement1Name: '',
      agreement2Name: '',
      typeOptions: ['normal', 'blocked', 'reverse'],
      country: {},
      accreditationTextBox: '',
      otherQualificationValue: '',
      accreditationTileOptions: [
        { value: 'other', text: 'Other' },
        { value: 'professional', text: 'Professional' },
        { value: 'accredited', text: 'Accredited' },
        { value: 'qualification', text: 'Qualification' },
      ],
      toggles: ['agreementOnEmail', 'qualificationRequired', 'additionalAccreditation', 'accreditationFirst'],
    };
  },
  computed: {
    ...mapGetters('systemAgreements', ['getAgreementFieldByName']),
    otherText() {
      return 'TEST TEXT BOX';
    },
    showOtherText() {
      let showTextBox = false;
      if (this.model && this.model.accreditationText) {
        showTextBox = this.isOther(this.model.accreditationText);
      }
     return showTextBox;
    },
  },
  watch: {
    countryData(val) {
      this.model = Object.assign({}, val);
      const { accreditationText } = Object.assign({}, val);
      this.accreditationTextBox = accreditationText;
      if (this.isOther(val.accreditationText)) this.model.accreditationText = 'other';
      const {
        agreement1,
        agreement2,
      } = this.model;

      this.agreement1Name = agreement1.name;
      this.agreement2Name = agreement2.name;
    },
  },
  methods: {
    isOther(value) {
      return !(['professional', 'accredited', 'qualification'].includes(value.toLowerCase()));
    },
    handleSubmit(country) {
      const data = {
        ...country,
        accreditationText: this.isOther(country.accreditationText) ? this.accreditationTextBox : country.accreditationText,
        agreementId1: this.getAgreementFieldByName('agreementId', this.agreement1Name),
        agreementId2: this.getAgreementFieldByName('agreementId', this.agreement2Name),
      };
      this.$emit('onUpdateCountry', data);
    },
  },
};
</script>
